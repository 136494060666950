<template>
    <div class="max-w-full p-4 rounded bg-gray-200 font-brand">
        <div class="max-w-7xl mx-auto">
            <div class="w-full bg-brand p-2 rounded-md">
                <label for="" class="text-2xl text-white">Schedule List</label>
            </div>
            <div class="text-left py-2 flex justify-between">
                <div>
                    <nav-link  av-link v-if="ability.includes('Schedule Create') || ability.includes('*')" to="/admin/schedule-create" class="bg-blue-500 text-white py-2 rounded-md">
                        Create Schedule
                    </nav-link>
                </div>
                <div class="flex gap-3">
                    <select name="plan" id="plan" class="py-1.5 rounded-md p-2 focus:outline-none" v-model="showSchedule" @click="availability(showSchedule)">
                        <option value="1" selected> Available Schedule </option>
                        <option value="2"> Old Schedule </option>
                    </select>
                </div> 


            </div>
            <table v-if="ability.includes('Schedule List') || ability.includes('*')" class="table-auto bg-white rounded-md mx-auto w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr class="border">
                        <th  scope="col" class="px-6 py-3 border-r">SL</th>
                        <th  scope="col" class="px-6 py-3">
                            <div class="flex min-w-max gap-1">
                                <div class=" bg-indigo-200 text-indigo-500 px-2 py-0.5 rounded-lg">Date</div>
                                <div class="bg-sky-200 text-sky-500 px-2 py-0.5 rounded-lg">Time</div>
                                <div class="bg-green-200 text-brand px-2 py-0.5 rounded-lg">Place</div>
                            </div>
                        </th>
                        <th  scope="col" class="px-6 py-3">
                            <div class="flex min-w-max gap-1">
                                <div class="px-2 py-0.5 bg-sky-200 text-sky-500 rounded-lg">Slot</div>
                                <div class="px-2 py-0.5 bg-purple-200 text-purple-500 rounded-lg">Threshold</div>
                            </div>
                        </th>
                        <th  scope="col" class="px-6 py-3">Appointment</th>
                        <th  scope="col" class="px-6 py-3">Assigned Mentor</th>
                        <th  v-if="ability.includes('Schedule Edit') || ability.includes('*')" scope="col" class="px-6 py-3">Action</th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr v-for="(schedule, index) in schedules.data" :key="schedule.id" class="hover:bg-gray-100 bg-white border-b">
                        <td scope="row" class="border-r border-b p-4">{{ ++index }}</td>
                        <td class="border-r border-b p-4">
                            <div class="flex flex-col gap-4 items-center justify-center">
                                <div class="py-px px-2 text-sm rounded-lg bg-indigo-100 text-indigo-500 font-semibold">
                                    {{ schedule.date }}
                                </div>
                                <div class="py-px px-2 text-md rounded-lg bg-indigo-100 text-sky-500 font-semibold">
                                    {{ schedule.time_schedule.start_time }} - {{ schedule.time_schedule.end_time }}
                                </div>
                                <div class="py-px px-2 rounded-lg bg-green-200 text-brand text-md font-semibold">
                                    {{ schedule.chamber.name }}
                                </div>
                            </div>
                        </td>
                        <td class="border-r border-b p-4">
                            <div v-for="(threshold, index) in schedule.slot_threshold" :key="index">
                                <div  class="flex gap-1 min-w-max py-1 items-center">
                                    <div class="grow-0 shrink-0 w-8 h-8 text-sm flex justify-center items-center bg-sky-200/50 text-sky-500 rounded-full">{{ threshold.slot }}</div>
                                    <div class="grow-0 shrink-0 w-8 h-8 text-sm flex justify-center items-center bg-purple-200/50 text-purple-500 rounded-full">{{ threshold.threshold }}</div>
                                    <div class="grow shrink text-sm text-left">{{ schedule_availability.support_types[index] }}</div>
                                </div>
                            </div>
                        </td>
                        <td class="border-r border-b p-4">
                            <div class="flex flex-col gap-4">
                                <div v-for="(appointment, index) in schedule.appointments" :key="index">
                                    <span class="text-sky-500"> {{ appointment }} ({{ schedule_availability.support_types[index] }})</span>
                                </div>
                                <div>
                                    <nav-link :to="{ name: 'adminAppointmentIndex', params: { scheduleId: schedule.id }}" class="bg-blue-500 text-white py-2 rounded-lg">Appointments</nav-link>
                                </div>
                            </div>

                        </td>
                        <td class="border-r border-b p-2">
                            <div class="grid gap-2">
                                <div class="border border-dashed rounded-md border-blue-400 p-2 pt-1" v-for="(mentors, index) in schedule.mentors" :key="index">
                                    <div class="text-left text-lg">{{ schedule_availability.support_types[index] }} : </div>
                                    <div class="flex gap-2 flex-wrap">
                                        <div  v-for="(name, index) in mentors" :key="index" class="py-px px-2 text-sm rounded-lg bg-indigo-100 text-indigo-500">
                                            {{ name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td v-if="ability.includes('Schedule Edit') || ability.includes('*')" class="border-b p-2">
                            <div class="flex gap-1 justify-center items-center">
                                <nav-link :to="{ name:'ScheduleEdit',  params:{ scheduleId: schedule.id } }" title="Edit">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                </nav-link>
                            </div>
                        </td> 
                        
                    </tr>
                </tbody>
            </table>
            <VueTailwindPagination :current="currentPage" :per-page="per_page" :total="total" @page-changed="pageChanged($event)" />
        </div>
    </div>
</template>

<script> 
import Select from '../../../components/select.vue'
import Btn from '../../../components/btn.vue'
import { Calendar, DatePicker } from 'v-calendar'
import NavLink from "../layouts/nav-link.vue";
import 'v-calendar/dist/style.css';
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination/'
import '@ocrv/vue-tailwind-pagination/dist/style.css'
export default {
    components: {
        Select,
        Calendar,
        DatePicker,
        Btn,
        NavLink,
        VueTailwindPagination

    },
   data() {
    return {
        date: new Date(),
        timezone: '',
        time_schedule:new Date(),
        slot_threshold:new Date(),
        currentPage:1,
        per_page: this.per_page || '',
        total: this.total || '',
        showSchedule:1,
    };
  },
   created() {
       this.$store.dispatch('adminSchedule/getSchedules', {
                availability:this.showSchedule
            });
    },
    watch: {
        date(newDate, oldDate) {
            this.searchSchedule();
        },
        chamberId(newChamberId, oldChamberId) {
            this.searchSchedule();
        },
    },
     computed:{
        schedules(){
            return this.$store.state.adminSchedule.schedules.schedules || '';
        },

        per_page(){
           return this.schedules.per_page || ''
        },

        total(){
           return this.schedules.total || ''
        },


        schedule_availability(){
            return this.$store.state.adminSchedule.schedules || '';
        },
        ability(){
            return this.$store.state.auth.abilities;
        }
     },
     methods: {
       availability(id){
           this.$store.dispatch('adminSchedule/getSchedules', {
                availability:id
            });
       },
        pageChanged(pageNumber){
            this.currentPage = pageNumber;
            this.getData();
        },
        
        getData(){
            this.$store.dispatch('adminSchedule/getSchedules',{
                availability:this.showSchedule,
                page: this.currentPage
            });
        }
     }

}
</script>